<template>
  <div v-if="filled" class="content profile-content">
    <ProfileNavbar :title="$t('profile.testing.title')"></ProfileNavbar>
    <div class="mob-menu-title">
      <!-- <router-link tag="a" to="/profile" class="mob-menu-back">
        <img src="images/profile/arrow-back.svg">
      </router-link> -->
      {{ $t('profile.testing.title') }}
    </div>
    <div class="clearfix">
                 <!-- <div class="bg-blue">
                     <div class="col-md-12 testing-tabs">
                         <div class="tabsMenu">
                             <ul class="nav nav-tabs d-flex flex-row">
                                 <li class="nav-item">
                                     <a class="nav-link testing-tabs-title text-center" @click.prevent="setActive('online')" :class="{ active: isActive('online') }" href="#online">{{ $t("profile.testing.online-title") }}</a>
                                 </li>
                                 <li class="nav-item">
                                     <a class="nav-link testing-tabs-title text-center" @click.prevent="setActive('offline')" :class="{ active: isActive('offline') }" href="#offline">{{ $t("profile.testing.offline-title") }} <span class="testing-tabs-title-add">{{ $t("profile.testing.offline-title-2") }}</span></a>
                                 </li>
                             </ul>
                         </div>
                     </div>
                 </div> -->
      <div class="bg-blue">
        <div class="tab-content testing-tab-content" id="myTabContent">
          <div class="tab-pane fade" :class="{ 'active show': isActive('online') }" id="online">
            <div class="online-type profile-cards">
              <div class="profile-card">
                <img width="100" src="/images/profile/3.svg" alt="">
                <div class="profile-card-title">{{ $t('profile.testing.test-title-1') }}</div>
                <div class="profile-card-button">
                  <router-link tag="button" to="/quizzes-list" class="btn btn-blue">{{
                      $t('profile.testing.btn-go')
                    }}
                  </router-link>
                </div>
              </div>
              <div class="profile-card">
                <img width="100" src="/images/profile/2.svg" alt="">
                <div class="profile-card-title">{{ $t('profile.testing.test-title-2') }}</div>
                <div class="profile-card-button">
                  <router-link tag="button" to="/profqabilet" class="btn btn-blue">{{
                      $t('profile.testing.btn-go')
                    }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" :class="{ 'active show': isActive('offline') }" id="offline">
            <div class="offline-type row">
              <div class="col-md-12">
                <form class="testing-offline row">
                  <div class="testing-offline-title col-lg-12 text-center text-lg-left text-md-left">
                    {{ $t('profile.notes.modal-title') }}
                  </div>
                  <div class="col-lg-6 col-md-6 col-12 test-info-col">
                    <div class="mb-3">
                      {{$t('profile.settings.date-test')}}
                    </div>
                    <v-calendar title-position="left"
                                class="calendar-test-date"
                                :attributes='attrs'
                                :min-date="new Date()"/>
                    <button class="select-test-day btn-blue">{{ $t('profile.testing.btn-select') }}</button>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12 test-info-col">
                    <div class="form-group d-flex flex-column">
                      <label class="form-label">{{ $t('profile.testing.select-address') }}</label>
                      <el-select v-model="select_address" :placeholder="$t('profile.testing.select-address')">
                        <el-option
                            v-for="item in [{
                                                label: $t('profile.testing.address-1'),
                                                value: 'address1'
                                                }, {
                                                label: $t('profile.testing.address-2'),
                                                value: 'address2'
                                                }]"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="mb-4">
                      {{$t('profile.settings.time-test')}}
                    </div>
                    <div class="time-check-wrapper">
                      <div class="time-check">
                        <label class="time-check-label" for="time-1">
                          <input class="time-check-input" type="radio" name="time" id="time-1" value="1" checked>
                          <div class="time-check-block">
                            <span class="time-checkmark"></span>
                            <span class="time-check-label-title">12:00</span>
                          </div>
                        </label>
                      </div>
                      <div class="time-check">
                        <label class="time-check-label" for="time-2">
                          <input class="time-check-input" type="radio" name="time" id="time-2" value="2">
                          <div class="time-check-block">
                            <span class="time-checkmark"></span>
                            <span class="time-check-label-title">13:00</span>
                          </div>
                        </label>
                      </div>
                      <div class="time-check">
                        <label class="time-check-label" for="time-3">
                          <input class="time-check-input" type="radio" name="time" id="time-3" value="3">
                          <div class="time-check-block">
                            <span class="time-checkmark"></span>
                            <span class="time-check-label-title">14:00</span>
                          </div>
                        </label>
                      </div>
                      <div class="time-check">
                        <label class="time-check-label" for="time-4">
                          <input class="time-check-input" type="radio" name="time" id="time-4" value="4" disabled>
                          <div class="time-check-block">
                            <span class="time-checkmark"></span>
                            <span class="time-check-label-title">15:00</span>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div class="form-group d-flex flex-column pt-3">
                      <label class="form-label">{{ $t('profile.testing.select-test') }}</label>
                      <el-select v-model="select_test" :placeholder="$t('profile.testing.select-test')">
                        <el-option
                            v-for="item in [{
                                                    label: $t('references.language-ru'),
                                                    value: 'ru'
                                                    }, {
                                                    label: $t('references.language-kz'),
                                                    value: 'kz'
                                                    }]"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="form-group mb-0 pt-3">
                      <label class="form-label">{{ $t('profile.testing.full-name') }}</label>
                      <el-input v-model="full_name" :placeholder="$t('profile.testing.full-name')"></el-input>
                    </div>
                  </div>
                  <div class="col-lg-12 text-center">
                    <b-button class="btn mx-auto btn-send btn-blue" v-b-modal.modal-sent>
                      {{ $t('profile.testing.btn-send') }}
                    </b-button>
                  </div>

                  <b-modal id="modal-sent" class="modal-sent" hide-footer hide-header centered>
                    <button class="btn-close-top" @click="$bvModal.hide('modal-sent')">
                      <img width="12" src="/images/profile/close.svg" alt="">
                    </button>
                    <img width="96" src="/images/profile/success-sent.svg" alt="">
                    <p class="modal-sent-title">{{ $t('profile.testing.sent') }}</p>
                    <button class="sent-btn" @click="$bvModal.hide('modal-sent')">{{
                        $t('profile.testing.btn-close')
                      }}
                    </button>
                  </b-modal>

                  <b-modal id="modal-error" class="modal-error" hide-footer hide-header centered>
                    <button class="btn-close-top" @click="$bvModal.hide('modal-error')">
                      <img width="12" src="/images/profile/close.svg" alt="">
                    </button>
                    <img width="96" src="/images/profile/error-modal.svg" alt="">
                    <p class="my-3 modal-error-title">{{ $t('profile.testing.error') }}</p>
                    <p class="mb-3 modal-error-subtitle">{{ $t('profile.testing.reason-title') }}</p>
                    <p class="modal-error-desc">{{ $t('profile.testing.reason-desc') }}</p>
                  </b-modal>

                  <b-modal id="modal-add-age" class="modal-add-age" hide-footer hide-header centered>
                    <button class="btn-close-top" @click="$bvModal.hide('modal-add-age')">
                      <img width="12" src="/images/profile/close.svg" alt="">
                    </button>
                    <div class="selected-age-title">
                      {{ $t('profile.testing.select-age') }}
                    </div>
                    <div class="selected-age" ref="age">

                    </div>
                    <el-slider
                        v-model="valueAge"
                        :max="30"
                        :show-tooltip="false"
                        :format-tooltip="formatTooltip"
                    ></el-slider>
                    <b-button class="mx-auto select-age btn-blue" @click="$bvModal.hide('modal-add-age')">
                      {{ $t('profile.testing.btn-select') }}
                    </b-button>
                  </b-modal>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import VCalendar from 'v-calendar';

export default {
  name: 'Parent',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      filled:false,
      showAge: '',
      activeItem: 'online',
      select_address: '',
      select_test: '',
      full_name: '',
      isHiddenSelectType: false,
      isHiddenOnline: true,
      isHiddenOffline: true,
      valueAge: 15,
      user: {
        phone: '',
        locale: '',
      },
      attrs: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
      ],
    }
  },
  beforeCreate() {
    Vue.use(VCalendar)
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.filled = res.data.data.filled;
          this.user.phone = res.data.data.phone;
          this.user.locale = localStorage.getItem('locale');
          if(this.filled!=true){
            Vue.toastr({
              message: this.$t('profile.testing.warning'),
              description: this.$t('main-page.profile-filled'),
              type: 'warning'
            });
            this.$router.push('/profile')
          }
        })
        .catch((e) => {

        });

  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
    formatTooltip(val) {
      this.$refs.age.innerText = val;
    },
    profqabilet(lang) {
      let url = `https://profqabilet.elumiti.kz/?phone=${this.user.phone}&lang=${lang}`;
      window.open(url, '_blank');
    },
  },
}
</script>
<style lang="postcss" scoped>
/deep/ .vc-pane {
  padding: 16px 16px 76px 16px;
}

/deep/ .is-today span {
  color: #04C2F4 !important;
}

/deep/ .is-today .vc-highlight {
  background-color: #fff !important;
}

/deep/ .is-today .vc-highlight:focus {
  color: #fff !important;
}

.vc-day-content:focus {
  background-color: #04C2F4 !important;
  color: #fff !important;
  border-radius: 0 !important;
}

/deep/ .is-today .vc-day-content:focus {
  background-color: #04C2F4 !important;
  color: #fff !important;
  border-radius: 0 !important;
}
.testing-tab-content {
    max-width: 1120px;
}
@media screen and (max-width: 991px) {
  /deep/ .testing-offline .vc-container {
    border: 1px solid #fff !important;
  }

  /deep/ .testing-offline .vc-pane {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
  }

  /deep/ .testing-offline .vc-arrows-container.title-left {
    justify-content: space-between;
  }

  /deep/ .testing-offline .vc-header.align-left {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .profile-card {
    flex-wrap: wrap;
    justify-content: center;
  }

  .profile-card-title {
    margin: 15px 0;
    width:100%;
  }
}
</style>




